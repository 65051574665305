$primary: #2c557a;
$primary-light: #6d89b1;
$border: #eee;
$text: #222;
$highlight: #00ba53;
$height: calc(100vh - 71px);
$warning: #b75d5d;
$succes: #05b361;
$smallphone: 375px;
$tablet: 781px;
$desktop: 981px;
$radius: 25px;
$inprogress: #e4a359;
