@use "@angular/material" as mat;
@use "variables" as v;

html,
body {
    height: 100%;
    box-sizing: border-box;
    scrollbar-gutter: stable;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    line-height: 21px;
    color: v.$text;
    background: #f9f9f9;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

a {
    color: v.$primary-light;
}

::placeholder,
input {
    font-family: "Inter", sans-serif;
}

/* material theming */

$md-primary: (
    50: #e6ebef,
    100: #c0ccd7,
    200: #96aabd,
    300: #6b88a2,
    400: #4c6f8e,
    500: #2c557a,
    600: #274e72,
    700: #214467,
    800: #1b3b5d,
    900: #102a4a,
    A100: #84b6ff,
    A200: #5198ff,
    A400: #1e7aff,
    A700: #046bff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff
    )
);

$material-primary: mat.m2-define-palette($md-primary);
$material-accent: mat.m2-define-palette($md-primary, 500, 900, A100);
$material-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $material-primary,
            accent: $material-accent
        ),
        density: 0
    )
);

$custom-typography: mat.m2-define-typography-config(
    $font-family: "Inter, sans-serif",
    $headline-5: mat.m2-define-typography-level(20px, 40px, 700, $letter-spacing: -0.04em),
    $headline-6: mat.m2-define-typography-level(22px, 25px, 700, $letter-spacing: -0.04em),
    $subtitle-1: mat.m2-define-typography-level(15px, 25px, 700, $letter-spacing: -0.04em),
    $subtitle-2: mat.m2-define-typography-level(16px, 25px, 700, $letter-spacing: -0.04em),
    $body-1: mat.m2-define-typography-level(13px, 21px, 400, $letter-spacing: -0.04em),
    $body-2: mat.m2-define-typography-level(13px, 21px, 400, $letter-spacing: -0.04em),
    $button: mat.m2-define-typography-level(13px, 21px, 500, $letter-spacing: -0.04em)
);

$tablet-desktop-typography: mat.m2-define-typography-config(
    $font-family: "Inter, sans-serif",
    $headline-5: mat.m2-define-typography-level(26px, 40px, 700, $letter-spacing: -0.04em),
    $headline-6: mat.m2-define-typography-level(22px, 25px, 700, $letter-spacing: -0.04em),
    $subtitle-1: mat.m2-define-typography-level(16px, 25px, 700, $letter-spacing: -0.04em),
    $subtitle-2: mat.m2-define-typography-level(16px, 25px, 700, $letter-spacing: -0.04em),
    $body-1: mat.m2-define-typography-level(14px, 21px, 400, $letter-spacing: -0.04em),
    $body-2: mat.m2-define-typography-level(14px, 21px, 400, $letter-spacing: -0.04em),
    $button: mat.m2-define-typography-level(14px, 21px, 500, $letter-spacing: -0.04em)
);

@include mat.all-component-themes($material-theme);

@media (max-width: v.$tablet) {
    @include mat.all-component-typographies($custom-typography);
}

@media (min-width: v.$tablet) {
    @include mat.all-component-typographies($tablet-desktop-typography);
}

@include mat.elevation-classes();
@include mat.app-background();

/* typography */

a {
    text-decoration: none;
}

.mat-typography,
.mat-typography p {
    letter-spacing: -0.04em !important;
}

/* scrollbar */

body {
    scrollbar-arrow-color: rgb(202, 202, 202);
    scrollbar-track-color: rgb(250, 250, 250);
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(190, 190, 190, 0.8);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(170, 170, 170, 1);
}

::-webkit-scrollbar-track {
    background-color: rgba(206, 206, 206, 0.4);
}

/* container */

.container {
    width: 1150px;
    max-width: 100%;
    margin: 0 auto;
}

/* page titles */

.title {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 25px;
}

@media (min-width: v.$tablet) {
    .title {
        font-size: 16px;
    }
}

/* headerbar */

.headerbar {
    padding: 15px;
    background: #fdfdfd;
    margin: -12px -15px 20px;
    border-bottom: 1px solid v.$border;
}

.headerbar__container {
    display: flex;
    flex-direction: column;
    max-width: 1150px;
    margin: 0 auto;
}

@media (min-width: v.$tablet) {
    .headerbar__container {
        flex-direction: row;
        align-items: center;
    }
}

.headerbar__title {
    margin: -6px 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
}

@media (min-width: v.$tablet) {
    .headerbar__title {
        margin: -1px 10px 0 0;
    }
}

.headerbar__title span {
    color: v.$primary;
}

.headerbar__title__shorten {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: v.$text !important;
}

.headerbar__buttons {
    display: flex;
    gap: 10px;
    flex-flow: wrap;
}

@media (min-width: v.$tablet) {
    .headerbar__buttons {
        margin: 0 0 0 auto;
        flex-flow: nowrap;
    }
}

.headerbar__buttons__item {
    flex: 1 1 auto;
    border: 1px solid v.$border !important;
}

@media (min-width: v.$tablet) {
    .headerbar__buttons__item {
        flex: 0 0 auto;
    }
}

.headerbar__buttons__item mat-icon {
    margin: -1px 4px 0 0;
    width: 22px;
    height: 22px;
    font-size: 22px;
}

/* scroll container */

.scroll {
    padding-top: 65px;
}

/* material button */

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    padding: 8px 16px !important;
    line-height: 30px !important;
    letter-spacing: -0.04em !important;
    min-height: 48px;
    border-radius: v.$radius !important;
}

.header__menu .mat-mdc-outlined-button {
    min-height: auto;
    padding: 0 6px 0 15px !important;
    height: 32px;
}

.mat-mdc-button-disabled .mat-icon {
    color: #7b7b7b;
}

/* material snackbar */

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: #ffffff !important;
    border-radius: v.$radius !important;
}

body .mat-mdc-snack-bar-container {
    margin: 13px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: #333 !important;
}

/* material form */

html {
    --mat-form-field-subscript-text-tracking: 0;
}

.mat-mdc-form-field {
    line-height: 1.225;
}

body .mat-mdc-text-field-wrapper {
    border-radius: v.$radius;
}

.mat-mdc-form-field .mdc-line-ripple {
    display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fff !important;
    border: 1px solid #eee;
}

.mdc-text-field__input {
    position: relative;
    top: 2px;
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
    font-size: var(--mdc-typography-body1-font-size, 15px);
}

.mdc-text-field--filled {
    --mdc-filled-text-field-container-shape: 15px !important;
}

/* mat select */

.mat-mdc-select-panel {
    border-radius: v.$radius !important;
}

/* mat autocomplete */

.mat-mdc-autocomplete-panel {
    border-radius: v.$radius !important;
}

/* material tooltip */

.mdc-tooltip__surface {
    font-size: 12px !important;
    letter-spacing: normal !important;
}

/* material expansion panel */

.mat-expansion-panel {
    margin-bottom: -1px !important;
    box-shadow: none !important;
    border: 1px solid v.$border;
}

.mat-expansion-panel:first-of-type {
    border-radius: v.$radius v.$radius 0 0 !important;
}

.mat-expansion-panel:last-of-type {
    border-radius: 0 0 v.$radius v.$radius !important;
}

.mat-expansion-panel:first-of-type:last-of-type {
    margin-bottom: 10px !important;
    border-radius: v.$radius !important;
}

.mat-expansion-panel-spacing {
    margin-bottom: 15px !important;
}

.mat-expansion-panel-header {
    padding: 0 15px !important;
    line-height: 19px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

@media (min-width: v.$tablet) {
    .mat-expansion-panel-header {
        font-size: 14px !important;
    }
}

.mat-expansion-panel-body {
    padding: 0 15px !important;
}

.mat-expansion-panel-body p:last-of-type {
    margin-bottom: 20px;
}

.mat-expansion-indicator {
    position: relative;
    top: -3px;
    right: 6px;
}

.mat-expanded .mat-expansion-indicator {
    top: 0;
}

/* slide toggle */

.mat-mdc-slide-toggle .mdc-switch {
    margin-right: 6px;
}

body .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: #b3b3b3;
}

/* button toggle */

.mat-button-toggle-group-appearance-standard {
    --mat-standard-button-toggle-shape: 25px !important;
}

/* material tabs */

.klicnav .mat-mdc-tab-link-container {
    max-width: 1150px;
    margin: 0 auto;
    overflow-x: auto;
}

.klicnav .mat-mdc-tab-link {
    padding: 0 23px;
    font-size: 13px;
    letter-spacing: -0.04em !important;
}

@media (min-width: v.$desktop) {
    .klicnav .mat-mdc-tab-link {
        font-size: 14px;
    }
}

.mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
    color: #383838 !important;
}

/* mat button toggle */

body .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: #f3f3f3;
}

/* mat radio */

.mat-mdc-radio-button .mdc-radio {
    margin-right: 7px !important;
    padding: 0 !important;
}

/* material menu */

.mat-mdc-menu-panel {
    border-radius: v.$radius !important;
}

.header__menucontainer {
    margin-top: 16px;
    border-top: 1px solid v.$border;
    border-radius: 0 0 v.$radius v.$radius !important;
}

html {
    --mat-menu-item-label-text-weight: 500;
    --mat-menu-item-label-text-tracking: -0.03em;
}

/* material dialog */

.dialog {
    max-width: 90vw !important;
}

.mat-mdc-dialog-surface {
    background-color: #fbfbfb !important;
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 15px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
    font-size: 14px !important;
    margin: 0;
}

@media (min-width: v.$tablet) {
    .mat-mdc-dialog-container .mdc-dialog__title {
        font-size: 16px !important;
    }
}

.mat-mdc-dialog-container .mdc-dialog__title span {
    color: v.$primary;
}

body .mat-mdc-dialog-container .mdc-dialog__content {
    color: #343434;
}

body .mdc-dialog__actions {
    padding: 10px 24px 15px;
}

/* streetsmart dialog styling */

.streetsmart-dialog {
    max-width: 100vw !important;
}

#cmt .windowcontrols {
    top: 44px;
}

/* material chips */

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    padding: 4px;
    background-color: #fff !important;
    border: 1px solid v.$border;
}

.mdc-evolution-chip--selected .mdc-evolution-chip__checkmark {
    color: v.$primary !important;
}

.mdc-evolution-chip--selected .mdc-evolution-chip__text-label {
    color: #333 !important;
}

/* mat table */

.mdc-data-table__header-cell {
    font-size: 13px;
    font-weight: 700 !important;
    color: #333;
}

body .mdc-data-table__row:last-child > .mdc-data-table__cell {
    border-bottom: 1px solid v.$border;
}

/* mat pagination */

html {
    --mat-table-row-item-outline-color: #eee;
    --mat-paginator-container-text-tracking: 0;
}

/* shop stepper */

.shopstepper .mat-horizontal-content-container {
    display: none;
}

.shopstepper .mat-step-label {
    min-width: 39px;
    font-size: 12px;
}

@media (min-width: v.$tablet) {
    .shopstepper .mat-step-label {
        font-size: 14px;
    }
}

/* viewer documenten */

.viewer .attachments {
    position: absolute;
    left: 0;
    bottom: 0;
    max-height: 45vh;
    width: v.$tablet;
    max-width: 100%;
    overflow: auto;
    z-index: 998;
    padding: 15px;
    background: #fff;
    border: 1px solid v.$border;
}

@media (min-width: v.$tablet) {
    .viewer .attachments {
        top: 63px;
        bottom: inherit;
        left: 15px;
        width: 642px;
        max-height: calc(100vh - 157px);
    }
}

/* measuring snackbar */

.measuring {
    max-width: 90vw !important;
}

body .measuring .dialog__content {
    padding-top: 20px !important;
    text-align: center;
}

.measuring .dialog__actions {
    justify-content: center;
}

/* maplibre viewer */

.maplibregl-map {
    font-family: Inter, sans-serif !important;
}

.marker {
    height: 20px;
    width: 20px;
    border: 4px solid v.$primary;
    border-radius: 100%;
}

.todo .mapbox-gl-draw_ctrl-draw-btn {
    width: 39px !important;
    height: 39px !important;
    border-width: 0 !important;
    background-color: #fff;
}

/* shop map */

.mapboxgl-map {
    font-family: "Inter", sans-serif !important;
}

.geocoder-search-container {
    padding: 2px;
}

.geocoder-suggestions-list {
    max-height: 300px;
}

/* print map functionality on klic viewer */

.maplibregl-ctrl-top-right .maplibregl-ctrl {
    display: none;
    margin: 546px 15px 0 0 !important;
    box-shadow: none !important;
    background: none !important;
}

@media (min-width: v.$tablet) {
    .maplibregl-ctrl-top-right .maplibregl-ctrl {
        display: block;
    }
}

.maplibregl-ctrl-icon.maplibregl-export-control {
    position: relative;
    width: 67px;
    height: 67px;
    background-color: #fff !important;
    border-radius: 50%;
    border: 1px solid v.$border;
}

.maplibregl-ctrl-icon.maplibregl-export-control:hover {
    background-color: #f5f5f5 !important;
}

.maplibregl-ctrl-icon.maplibregl-export-control::before {
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    left: 23px;
    width: 20px;
    height: 20px;
    background-image: url("assets/print.svg");
    background-size: cover;
    background-repeat: no-repeat;
    color: v.$primary;
}

.maplibregl-ctrl-icon.maplibregl-export-control::after {
    content: "PDF";
    display: block;
    position: absolute;
    top: 38px;
    left: 24px;
    color: v.$primary;
    font-size: 9px;
}

.maplibregl-export-list {
    padding: 15px;
    background: #f7f7f7;
    border-radius: v.$radius;
}

.maplibregl-export-list button {
    margin-top: 7px;
    width: 100% !important;
    font-family: Inter, sans-serif;
}

.maplibregl-export-list select {
    font-family: Inter, sans-serif;
}

.maplibregl-export-list .print-table tr td:first-child {
    display: none;
}

.maplibregl-export-list .generate-button {
    visibility: hidden;
    position: relative;
}

.maplibregl-export-list .generate-button::after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "Genereren";
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    font-weight: 500;
    background: v.$primary;
    border-radius: v.$radius;
}

.la-ball-spin.la-2x {
    color: v.$primary !important;
}

.hidden-map {
    visibility: hidden;
}
